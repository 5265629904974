<script setup>
import { closeModal, confirmModal } from '@kolirt/vue-modal'
import { defineProps, reactive } from 'vue'
import { toast } from 'vue3-toastify'

const props = defineProps({
  title: String,
  form: Array,
})

const formData = reactive([])

props.form.forEach(() => {
  formData.push({ value: '' })
})

function handleConfirm() {
  if (formData.some((item) => !item.value)) {
    toast.error('Please fill all fields')
  } else {
    confirmModal(formData)
  }
}
</script>

<template>
  <SimpleModal :title="props.title" size="sm">
    <div class="row">
      <div v-for="(item, index) in props.form" :key="index" class="col-12 mb-3">
        <b-form-group :label="item.label">
          <b-form-select
            v-if="item.options"
            v-model="formData[index].value"
            :options="item.options"
          ></b-form-select>
          <b-form-textarea
            v-else-if="item.type === 'textarea'"
            :type="item.type"
            rows="4"
            max-rows="6"
            v-model="formData[index].value"
          ></b-form-textarea>
          <b-form-input
            v-else
            :type="item.type"
            v-model="formData[index].value"
          ></b-form-input>
          
        </b-form-group>

        <img v-if="(item.label === 'Image') && formData[index].value" :src="formData[index].value" alt="illustration" width="80" height="80" />
      </div>
    </div>

    <template #footer>
      <button @click="closeModal()" class="btn btn-secondary">Close</button>
      <button
        @click="handleConfirm()"
        class="btn btn-success"
      >
        Confirm
      </button>
    </template>
  </SimpleModal>
</template>

<style scoped>

</style>