<template>
  <div>
    <Loader v-if="isLoading" color="#000" />
    <div class="top">
      <div class="row">
        <div class="col-6">
          <p class="title">FAQs</p>
        </div>
        <div class="col-6 text-end">
          <b-button @click="handleOpenModalCreate" class="new ms-auto">
            New FAQ
          </b-button>
        </div>
      </div>
    </div>
    <div class="content" v-if="faqs[0]">
      <div v-for="(item, index) in faqs" :key="item.id" class="row">
        <div class="col-12">
          <p class="title">
            {{ index + 1
            }}{{
              index === 0
                ? 'st'
                : index === 1
                ? 'nd'
                : index === 2
                ? 'rd'
                : 'th'
            }}
            FAQ
          </p>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Question">
            <b-form-input
              v-model="faqs[index].title"
              type="text"
              :disabled="disabledForm[index].title"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Answer">
            <b-form-textarea
              v-model="faqs[index].subtitle"
              rows="3"
              max-rows="6"
              :disabled="disabledForm[index].subtitle"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-12 mb-3 d-flex align-items-end text-end">
          <b-button @click="deleteFAQs(item.id)" class="delete btn ms-auto">
            Delete
          </b-button>
          <b-button @click="updateFAQs(item.id)" class="update ms-2">
            Update
          </b-button>
        </div>

        <div>
          <hr v-if="index !== faqs.length - 1" class="my-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import Loader from '@/landing/components/Loader.vue'
import { openModal } from '@kolirt/vue-modal'
import DefaultModal from '@/admin/components/DefaultModal.vue'

export default {
  name: 'FAQsPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      faqs: [],
      disabledForm: [],
    }
  },
  created() {
    this.getFAQs()
  },
  methods: {
    handleOpenModalCreate() {
      openModal(DefaultModal, {
        title: 'Create FAQ',
        form: [
          {
            label: 'Question',
            type: 'text',
          },
          {
            label: 'Answer',
            type: 'text',
          },
        ],
      })
        .then((data) => {
          this.createFAQs(data)
        })
        .catch(() => {
          return
        })
    },
    async getFAQs() {
      this.isLoading = true
      await api
        .get('faqs')
        .then((response) => {
          this.faqs = response.data
          const disabledItems = response.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems
        })
        .catch((error) => {
          console.error(error)
          toast('Failed to fetch data', {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async updateFAQs(id) {
      const index = this.disabledForm.findIndex((item) => item.id === id)
      let updatedItem = this.disabledForm[index]
      if (updatedItem.title) {
        updatedItem = {
          id,
          title: false,
          subtitle: false,
        }

        return (this.disabledForm[index] = updatedItem)
      }

      if (this.faqs[index].title === '' || this.faqs[index].subtitle === '') {
        return toast('Please fill all fields', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .patch('faqs-update', {
          id,
          title: this.faqs[index].title,
          subtitle: this.faqs[index].subtitle,
        })
        .then((response) => {
          this.faqs = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false

          updatedItem = {
            id,
            title: true,
            subtitle: true,
          }

          this.disabledForm[index] = updatedItem

          return
        })
    },
    async deleteFAQs(id) {
      //confirm delete
      if (!confirm('Are you sure you want to delete this item?')) {
        return
      }

      this.isLoading = true
      await api
        .delete('faqs-delete', {
          data: {
            id,
          },
        })
        .then((response) => {
          this.faqs = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async createFAQs(data) {
      const formData = {
        title: data[0].value,
        subtitle: data[1].value,
      }

      if (formData.title === '' || formData.subtitle === '') {
        return toast('Please fill all fields', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .post('faqs-create', {
          title: formData.title,
          subtitle: formData.subtitle,
        })
        .then((response) => {
          const disabledItems = response.data.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems

          this.faqs = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  border-bottom: 1px solid #e6e6e6;
  padding: 5vh 2.5vh 0 2.5vh;
  background: #fff;
  height: 15vh;
}

.top .title {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
}

.top .btn,
.modal .btn.save {
  height: max-content;
  background: #28a745;
  border: 1px solid #28a745;
  color: #fff;
}

.top .btn:hover,
.top .btn:active,
.top .btn:active,
.modal .btn.save:hover,
.modal .btn.save:active,
.modal .btn.save:active {
  background: #28a745;
  border-color: #28a745;
  box-shadow: 0 8px 25px -8px #28a745;
}

.content {
  padding: 2.5vh;
  background: #f5f5f5;
  border-bottom-left-radius: 3vh;
  border-bottom-right-radius: 3vh;
  height: 80vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content .title {
  font-size: 20px;
  font-weight: bold;
  color: #111111;
}

.content fieldset {
  margin-bottom: 0 !important;
}

.content .update.btn {
  height: max-content;
  background: #ff5a2a;
  border: 1px solid #ff5a2a;
}

.content .update.btn:hover,
.content .update.btn:active,
.content .update.btn:active {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

.content .delete.btn {
  height: max-content;
  background: #dc3545;
  border: 1px solid #dc3545;
}

.content .delete.btn:hover,
.content .delete.btn:active,
.content .delete.btn:active {
  background: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 8px 25px -8px #dc3545;
}

@media (min-width: 1440px) {
  .top {
    padding: 5vh 15vh 0 15vh;
  }

  .content {
    padding: 5vh 15vh;
  }
}
</style>
