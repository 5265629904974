<template>
  <Loader v-if="isLoading" />
  <div class="home">
    <Navbar
      v-if="links.length"
      v-bind:links="links"
      @trigger-services="handleScrollServices()"
      @trigger-faq="handleScrollFaq()"
      @trigger-lang="handleLangOnChange()"
    />
    <b-button
      v-if="scrollY > 600"
      ref="scrollToTop"
      class="btn-scrolltop"
      @click="handleScrollTop()"
    >
      <SvgIcon type="mdi" :path="iconPath[5]" />
    </b-button>
    <b-button class="btn-whatsapp" @click="handleWhatsapp()">
      <SvgIcon type="mdi" :path="iconPath[8]" />
    </b-button>
    <div class="header">
      <img
        ref="headerIllustration"
        class="illustration"
        alt="header-illustration"
        draggable="false"
      />
      <p class="title mb-0">
        {{ this.lang === 'id' ? headings[0]?.title_id : headings[0]?.title_en }}
      </p>
      <p class="subtitle mb-0">
        {{
          this.lang === 'id'
            ? headings[0]?.subtitle_id
            : headings[0]?.subtitle_en
        }}
      </p>

      <div class="row button">
        <div class="col-12">
          <b-button @click="handleLink('Contact')" class="btn-contact">
            {{ this.lang === 'id' ? 'Hubungi Kami' : 'Contact Us' }}
          </b-button>
          <b-button @click="handleScrollReason()" class="btn-why">
            {{
              `${
                this.lang === 'id'
                  ? 'Kenapa harus Cat Sitter Indonesia'
                  : 'Why Cat Sitter Indonesia'
              }` + '? ↓'
            }}
          </b-button>
        </div>
      </div>

      <div class="row count">
        <div class="col-3" v-for="(item, index) in counts" :key="index">
          <div class="card bg-light mx-auto">
            <p
              id="counters"
              class="number mb-0"
              :data-counttarget="item.value"
            ></p>
            <p class="text mb-0">
              {{ this.lang === 'id' ? item.title_id : item.title_en }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sitters text-center">
      <p class="title">
        {{ this.lang === 'id' ? headings[1]?.title_id : headings[1]?.title_en }}
      </p>

      <div class="row">
        <div
          v-for="(item, index) in sitters"
          :key="index"
          class="col-6 col-lg-3 text-center"
        >
          <div class="card">
            <img
              class="picture"
              :src="item.img"
              :alt="item.name"
              draggable="false"
            />

            <div class="row mt-3">
              <div class="col-12">
                <a :href="item.ig" target="_blank">
                  <SvgIcon type="mdi" :path="iconPath[9]" />
                </a>

                <p class="name mb-0">{{ item.name }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p
                  class="d-flex align-items-center justify-content-center mb-0"
                >
                  {{ `${item.city} || ${item.rating}` }}
                  <SvgIcon type="mdi" :path="iconPath[4]" size="20" />
                </p>
                <p class="mb-0">
                  {{
                    `${
                      item.service === 'all'
                        ? 'Home/Care'
                        : item.service.charAt(0).toUpperCase() +
                          item.service.slice(1)
                    } Visit`
                  }}
                </p>
                <p class="mb-0">{{ item.level }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-1">
          <b-button @click="handleLink('Profile')" class="btn-seeall">
            {{ this.lang === 'id' ? 'Lihat Semua' : 'See All' }}
          </b-button>
        </div>
      </div>
    </div>

    <div ref="services" class="services text-center">
      <div
        class="elementor-shape elementor-shape-top"
        data-negative="false"
        bis_skin_checked="1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M0 0L2600 0 2600 69.1 0 0z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.5;"
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.25;"
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </div>
      <div
        class="elementor-shape elementor-shape-bottom"
        data-negative="false"
        bis_skin_checked="1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M0 0L2600 0 2600 69.1 0 0z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.5;"
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.25;"
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </div>
      <div class="parallax">
        <p class="title mb-0">
          {{
            this.lang === 'id' ? headings[2]?.title_id : headings[2]?.title_en
          }}
        </p>
        <div class="desc">
          {{
            this.lang === 'id'
              ? headings[2]?.subtitle_id
              : headings[2]?.subtitle_en
          }}
        </div>

        <div class="row">
          <div
            v-for="(item, index) in services"
            :key="index"
            class="col-12 col-lg-6"
          >
            <div class="card">
              <div class="row">
                <div class="col-4 text-center">
                  <img :src="item.img" class="photo" alt="" draggable="false" />
                </div>
                <div class="col-8">
                  <p class="title mb-0">
                    {{ this.lang === 'id' ? item.title_id : item.title_en }}
                  </p>
                  <p
                    class="description mb-0"
                    v-html="
                      formattedStringHTML(
                        this.lang === 'id'
                          ? item.subtitle_id
                          : item.subtitle_en,
                      )
                    "
                  ></p>

                  <b-button @click="handleLink('Contact')" class="btn-contact">
                    {{ this.lang === 'id' ? 'Hubungi Kami' : 'Contact Us' }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flow">
      <p class="title mb-0 text-center">
        {{ this.lang === 'id' ? headings[3]?.title_id : headings[3]?.title_en }}
      </p>
      <p class="description text-center">
        {{
          this.lang === 'id'
            ? headings[3]?.subtitle_id
            : headings[3]?.subtitle_en
        }}
      </p>

      <Splide
        ref="splideFlow"
        :options="splideFlowOptions"
        :has-track="false"
        aria-label="..."
        @splide:move="onMove"
        style="position: relative;"
      >
        <div class="state">
          <div
            @click="handleFlowState(index)"
            :class="{ active: index === activeSlide, column: true }"
            v-for="(item, index) in flows"
            :key="index"
          >
            <div class="number align-content-center text-center">
              {{ index + 1 }}
            </div>
            <p>
              {{ this.lang === 'id' ? item.title_id : item.title_en }}
            </p>
          </div>
        </div>
        <SplideTrack>
          <SplideSlide v-for="(item, index) in flows" :key="index">
            <div class="card">
              <div class="row">
                <div class="col-5 text-center">
                  <img :src="item.img" alt="Sample 1" />
                </div>
                <div class="col-7 align-content-center">
                  <p class="title mb-0">
                    {{ this.lang === 'id' ? item.title_id : item.title_en }}
                  </p>
                  <p class="description mb-0">
                    {{
                      this.lang === 'id' ? item.subtitle_id : item.subtitle_en
                    }}
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
        </SplideTrack>

        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev">
            <SvgIcon type="mdi" :path="iconPath[5]" />
          </button>
          <button class="splide__arrow splide__arrow--next">
            <SvgIcon type="mdi" :path="iconPath[6]" />
          </button>
        </div>
      </Splide>
    </div>

    <div v-if="this.galleries.length !== 0" class="album">
      <Splide :options="splideAlbumOptions" ref="splideAlbum" aria-label="...">
        <SplideSlide v-for="(item, index) in galleries" :key="index">
          <img :src="item.img" :alt="`Album image ` + (index + 1)" />
        </SplideSlide>
      </Splide>
    </div>

    <div ref="reason" class="reason">
      <p class="title mb-0">
        {{ this.lang === 'id' ? headings[4]?.title_id : headings[4]?.title_en }}
      </p>
      <p class="description">
        {{
          this.lang === 'id'
            ? headings[4]?.subtitle_id
            : headings[4]?.subtitle_en
        }}
      </p>
      <div class="row justify-content-center">
        <div class="col-4 text-center">
          <img ref="reasonIllustration" draggable="false" />
        </div>
        <div class="col-7">
          <div class="accordion" id="accordionReason">
            <div
              class="accordion-item"
              v-for="(item, index) in reasons"
              :key="index"
            >
              <h2 class="accordion-header">
                <button
                  :class="`accordion-button ${
                    index !== 0 ? 'collapsed' : null
                  }`"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapseReason${index}`"
                  :aria-expanded="index === 0"
                  :aria-controls="`#collapseReason${index}`"
                >
                  {{ this.lang === 'id' ? item.title_id : item.title_en }}
                </button>
              </h2>
              <div
                :id="`collapseReason${index}`"
                :class="`accordion-collapse collapse ${
                  index === 0 ? 'show' : null
                }`"
                data-bs-parent="#accordionReason"
              >
                <div class="accordion-body">
                  <p>
                    {{
                      this.lang === 'id' ? item.subtitle_id : item.subtitle_en
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="review" class="review text-center">
      <div
        class="elementor-shape elementor-shape-top"
        data-negative="false"
        bis_skin_checked="1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M0 0L2600 0 2600 69.1 0 0z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.5;"
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.25;"
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </div>
      <div
        class="elementor-shape elementor-shape-bottom"
        data-negative="false"
        bis_skin_checked="1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M0 0L2600 0 2600 69.1 0 0z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.5;"
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.25;"
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </div>
      <div class="parallax">
        <p class="title">
          {{
            this.lang === 'id' ? headings[5]?.title_id : headings[5]?.title_en
          }}
        </p>
        <Splide
          :options="splideReviewOptions"
          ref="splideReview"
          aria-label="..."
        >
          <SplideSlide v-for="(item, index) in reviews" :key="index">
            <div class="card">
              <SvgIcon type="mdi" :path="iconPath[7]" />

              <p class="text">
                {{ item.value }}
              </p>

              <div class="people">
                <div class="row">
                  <div class="col-auto">
                    <img :src="item.img" alt="Sample 1" class="photo" />
                  </div>
                  <div class="col-auto align-content-center text-start">
                    <p class="name mb-0">{{ item.name }}</p>
                    <div class="account mb-0">{{ item.account }}</div>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>

    <div ref="faq" class="faq">
      <p class="title mb-0">
        {{ this.lang === 'id' ? headings[6]?.title_id : headings[6]?.title_en }}
      </p>
      <p class="description">
        {{
          this.lang === 'id'
            ? headings[6]?.subtitle_id
            : headings[6]?.subtitle_en
        }}
      </p>
      <div class="row justify-content-center">
        <div class="col-6">
          <div class="accordion" id="accordionFaq">
            <div
              class="accordion-item"
              v-for="(item, index) in faqs"
              :key="index"
            >
              <h2 class="accordion-header">
                <button
                  :class="`accordion-button ${
                    index !== 0 ? 'collapsed' : null
                  }`"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapseFaq${index}`"
                  :aria-expanded="index === 0"
                  :aria-controls="`#collapseFaq${index}`"
                >
                  {{ item.title }}
                </button>
              </h2>
              <div
                :id="`collapseFaq${index}`"
                :class="`accordion-collapse collapse ${
                  index === 0 ? 'show' : null
                }`"
                data-bs-parent="#accordionFaq"
              >
                <div class="accordion-body">
                  <p>
                    {{ item.subtitle }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 text-center">
          <img ref="faqIllustration" draggable="false" />
        </div>
      </div>
    </div>

    <Footer
      v-if="links.length"
      v-bind:links="links"
      @trigger-services="handleScrollServices()"
      @trigger-faq="handleScrollFaq()"
    />
  </div>
</template>

<script>
import api from '@/api'
import SvgIcon from '@jamescoyle/vue-icon'
import {
  mdiCalendarPlusOutline,
  mdiChatProcessingOutline,
  mdiCat,
  mdiCashRegister,
  mdiStar,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiFormatQuoteOpen,
  mdiWhatsapp,
  mdiInstagram,
} from '@mdi/js'
import Navbar from '@/landing/components/Navbar.vue'
import Footer from '@/landing/components/Footer.vue'
import Loader from '@/landing/components/Loader.vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'

export default {
  name: 'HomePage',
  components: {
    SvgIcon,
    Navbar,
    Footer,
    Loader,
    Splide,
    SplideSlide,
    SplideTrack,
  },
  data() {
    return {
      isLoading: true,
      iconPath: [
        mdiCalendarPlusOutline,
        mdiChatProcessingOutline,
        mdiCat,
        mdiCashRegister,
        mdiStar,
        mdiArrowUpBold,
        mdiArrowDownBold,
        mdiFormatQuoteOpen,
        mdiWhatsapp,
        mdiInstagram,
      ],
      splideFlowOptions: {
        direction: 'ttb',
        height: window.innerWidth > 992 ? '23vw' : '55vw',
        perPage: 1,
        focus: 0,
        omitEnd: true,
      },
      splideAlbumOptions: {
        type: 'loop',
        perPage: window.innerWidth > 992 ? 5 : 2,
        perMove: 1,
        focus: 0,
        autoplay: true,
        interval: 2000,
      },
      splideReviewOptions: {
        type: 'loop',
        perPage: window.innerWidth > 992 ? 3 : 1,
        perMove: 1,
        focus: 0,
        autoplay: true,
        interval: 3000,
      },
      activeSlide: 0,
      scrollTimer: 0,
      scrollY: 0,
      lang: localStorage.getItem('lang') || 'en',
      links: [],
      headings: [],
      services: [],
      flows: [],
      galleries: [],
      reasons: [],
      reviews: [],
      faqs: [],
      sitters: [],
      counts: [],
    }
  },
  created() {
    window.scrollTo(0, 0)
    this.getLinks()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer) return
      this.scrollTimer = setTimeout(() => {
        this.scrollY = window.scrollY
        clearTimeout(this.scrollTimer)
        this.scrollTimer = 0
      }, 100)
    },
    handleCounter() {
      const counters = document.querySelectorAll('#counters')
      const speed = 1000

      counters.forEach((counter) => {
        const updateCount = () => {
          const target = +counter.getAttribute('data-counttarget')
          const count = +counter.innerText

          const inc = target / speed

          if (count < target) {
            counter.innerText = Math.ceil(count + inc)
            setTimeout(updateCount, 1)
          } else {
            counter.innerText = target + '+'
          }
        }

        updateCount()
      })
    },
    handleScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleLink(source) {
      if (source === 'Contact') {
        return window.open(
          this.links.find((item) => item.type === 'whatsapp').url,
          '_blank',
        )
      }

      this.$router.push({ name: source })
    },
    handleScrollReason() {
      this.$refs.reason.scrollIntoView({ behavior: 'smooth' })
    },
    handleScrollServices() {
      this.$refs.services.scrollIntoView({ behavior: 'smooth' })
    },
    handleScrollFaq() {
      this.$refs.faq.scrollIntoView({ behavior: 'smooth' })
    },
    formattedStringHTML(value) {
      return value.replace(/{{(.+?)}}/g, (_, g) => {
        return this[g.trim()]
      })
    },
    onMove(dest, index, prev) {
      this.activeSlide = index
    },
    handleFlowState(index) {
      this.$refs.splideFlow.go(index)
    },
    handleWhatsapp() {
      window.open(
        'https://api.whatsapp.com/send/?phone=%206287730903400&text=Halo%2C+Saya+tertarik+untuk+memesan+jasa+cat+sitter&app_absent=0',
        '_blank',
      )
    },
    handleLangOnChange() {
      this.lang = localStorage.getItem('lang')
    },
    async getLinks() {
      await api
        .get('links')
        .then((response) => {
          this.links = response.data
          this.getHeading()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getHeading() {
      await api
        .get('headings')
        .then((response) => {
          this.headings = response.data
          this.getCounts()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getCounts() {
      await api
        .get('counts')
        .then((response) => {
          this.counts = response.data
          this.getSitters()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getSitters() {
      await api
        .get('sitters')
        .then((response) => {
          this.sitters = response.data
            .sort(() => Math.random() - 0.5)
            .slice(0, 4)

          this.getServices()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getServices() {
      await api
        .get('services')
        .then((response) => {
          this.services = response.data
          this.getFlows()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getFlows() {
      await api
        .get('flows')
        .then((response) => {
          this.flows = response.data
          this.getGalleries()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getGalleries() {
      await api
        .get('galleries')
        .then((response) => {
          this.galleries = response.data
          this.getReasons()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getReasons() {
      await api
        .get('reasons')
        .then((response) => {
          this.reasons = response.data
          this.getReviews()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getReviews() {
      await api
        .get('reviews')
        .then((response) => {
          this.reviews = response.data
          this.getFaqs()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getFaqs() {
      await api
        .get('faqs')
        .then((response) => {
          this.faqs = response.data
          this.syncData()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    syncData() {
      this.$nextTick(() => {
        this.isLoading = false
        this.$refs.services.style.backgroundImage = `url(${this.headings[2].img})`
        this.$refs.review.style.backgroundImage = `url(${this.headings[5].img})`
        this.$refs.headerIllustration.src = this.headings[0].img
        this.$refs.reasonIllustration.src = this.headings[4].img
        this.$refs.faqIllustration.src = this.headings[6].img
        this.handleCounter()
        this.$refs.splideReview.go(3)
        this.$refs.splideReview.splide.go(0)
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-scrolltop {
  position: fixed;
  bottom: 2vw;
  left: 2vw;
  z-index: 1029;
  padding: 1vw;
  border: 0;
  background: #fdb23f;
  border-radius: 50%;
}

.btn-scrolltop:hover {
  background: #ff5a2a;
}

.btn-whatsapp {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  z-index: 1029;
  padding: 1vw;
  border: 0;
  border-radius: 50%;
}

.btn-whatsapp:hover {
  background: #25d366;
}

.header {
  background: #ff5a2a;
  border-bottom-left-radius: 2.5vw;
  border-bottom-right-radius: 2.5vw;
  padding: 3.4vw 10vw;
  position: relative;
}

.header p {
  color: #fff;
}

.header > .title {
  font-size: 3.02vw;
  font-weight: bold;
  width: 60%;
}

.header > .subtitle {
  font-size: 1.25vw;
  font-weight: 600;
  margin-top: 1.04vw;
  width: 40%;
}

.header .button {
  margin-top: 3.75vw;
  margin-bottom: -3vw;
}

.header .btn-contact {
  padding: 0.63vw 1.25vw;
  background: #fff;
  color: #ff5a2a;
  font-weight: 600;
  font-size: 1.25vw;
  border: 0;
  margin-right: 5vw;
  border-radius: 2.5vw;
}

.header .btn-contact:hover,
.header .btn-contact:active {
  background: #fdb23f;
  color: #fff;
}

.header .btn-why {
  padding: 0.63vw 1.25vw;
  background: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 1.25vw;
  border-radius: 2.5vw;
  border: 1px solid #fff;
}

.header .btn-why:hover,
.header .btn-why:active {
  background: #fff;
  color: #ff5a2a;
  border-color: #fff;
}

.header .illustration {
  width: 22vw;
  height: 28vw;
  position: absolute;
  bottom: 0;
  right: 7vw;
  pointer-events: none;
}

.header .count {
  position: relative;
  bottom: -7vw;
  width: 70%;
}

.header .count .card {
  border-radius: 2.5vw;
  padding-top: 0.68vw;
  padding-bottom: 1vw;
  text-align: center;
  width: 13.5vw;
  border: 0;
  box-shadow: 0 0 0.52vw 0 rgba(0, 0, 0, 0.3);
}

.header .count .card .number {
  font-size: 3vw;
  font-weight: bolder;
  color: #ff5a2a;
}

.header .count .card .text {
  font-size: 1vw;
  margin-top: 0.52vw;
  color: #ff5a2a;
}

.sitters {
  padding: 0 10vw;
  margin-top: 8vw;
}

.sitters > .title {
  font-size: 3.33vw;
  font-weight: bold;
  color: #ff5a2a;
  margin-bottom: 5vw;
}

.sitters .card {
  border-radius: 1vw;
  border: 0;
  background: #fff;
  padding: 3vw 2vw 2vw 2vw;
  transition: 0.5s;
  box-shadow: 0 14px 50px rgba(0, 0, 0, 0.04);
}

.sitters .card .picture {
  width: 6vw;
  height: 6vw;
  border-radius: 6vw;
  position: absolute;
  top: -3vw;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}

.sitters .card .name {
  font-size: 1.3vw;
  color: #000;
  font-weight: bold;
}

.sitters .card svg {
  color: #fcb23f;
}

.sitters .btn-seeall {
  padding: 0.63vw 1.25vw;
  background: #fff;
  color: #ff5a2a;
  font-weight: 600;
  font-size: 1.25vw;
  border-radius: 2.5vw;
  margin-top: 2.6vw;
  border: 1px solid #ff5a2a;
}

.sitters .btn-seeall:hover,
.sitters .btn-seeall:active {
  background: #ff5a2a;
  color: #fff;
  border: 1px solid #ff5a2a;
}

.services {
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.services .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.services .elementor-shape svg {
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.services .elementor-shape-top {
  top: -1px;
}

.services .elementor-shape-bottom {
  transform: rotate(180deg);
  bottom: -1px;
}

.services .elementor-shape-top svg,
.elementor-shape-bottom svg {
  height: 6vw;
}

.services .elementor-shape .elementor-shape-fill {
  fill: #f8eee2;
  transform-origin: center;
  transform: rotateY(0deg);
}

.services .parallax {
  padding: 8vw 10vw;
  text-align: center;
}

.services .parallax > .title {
  font-size: 3.33vw;
  font-weight: bold;
  color: #f8eee2;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.services .parallax > .desc {
  font-size: 1.35vw;
  margin-top: 1.04vw;
  margin-bottom: 2.92vw;
  color: #f8eee2;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.services .parallax .row .card {
  width: 98%;
  padding: 5vw 2.6vw;
  border: 0;
  outline: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0 14px 50px rgba(0, 0, 0, 0.04);
  border-radius: 1vw;
  text-align: left;
}

.services .parallax .row .card .photo {
  width: 8vw;
  height: 8vw;
  pointer-events: none;
}

.services .parallax .row .card .title {
  font-size: 1.5vw;
  font-weight: bold;
  color: #ff5a2a;
}

.services .parallax .row .card .description {
  font-size: 1.2vw;
  margin-top: 1.04vw;
  color: #707070;
}

.services .parallax .row .card .btn-contact {
  margin-top: 1.04vw;
  padding: 0.63vw 1.25vw;
  background: transparent;
  color: #ff5a2a;
  font-weight: 600;
  font-size: 1.25vw;
  border: 1px solid #ff5a2a;
  border-radius: 2.5vw;
}

.services .parallax .row .card .btn-contact:hover,
.services .parallax .row .card .btn-contact:active {
  background: #ff5a2a;
  color: #fff;
}

.flow {
  padding: 0 10vw;
  margin-top: 5.21vw;
  position: relative;
}

.flow > .title {
  font-size: 3.33vw;
  font-weight: bold;
  color: #ff5a2a;
}

.flow > .description {
  font-size: 1.35vw;
  margin-top: 1.04vw;
  margin-bottom: 1vw;
  color: #ff5a2a;
}

.flow .splide__arrow.splide__arrow--prev,
.flow .splide__arrow.splide__arrow--next {
  background: transparent;
  left: 5vw;
}

.splide__arrows--ttb .splide__arrow--prev svg,
.splide__arrows--ttb .splide__arrow--next svg {
  transform: unset;
  font-size: 5vw;
  color: #ff5a2a;
}

.flow .splide__slide {
  margin-left: 20vw;
  margin-right: 10vw;
}

.flow .state {
  position: absolute;
  top: 0;
  left: 4vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.flow .state .column {
  display: flex;
}

.flow .state .column:hover {
  cursor: pointer;
}

.flow .state .column:hover p {
  color: #ff5a2a;
  font-weight: bold;
}

.flow .state .column:hover .number {
  font-weight: bold;
  background: #ff5a2a;
  color: #fff;
}

.flow .state .number {
  border-radius: 5vw;
  background: transparent;
  color: #ff5a2a;
  width: 2vw;
  height: 2vw;
  border: 1px solid #ff5a2a;
  margin: 0.5vw 1vw 0.5vw 0;
}

.flow .state p {
  font-size: 1.5vw;
  color: #707070;
  margin: 0.5vw 0;
  display: inline;
}

.flow .state .active .number {
  font-weight: bold;
  background: #ff5a2a;
  color: #fff;
}

.flow .state .active p {
  color: #ff5a2a;
  font-weight: bold;
}

.flow .splide li {
  align-content: center;
}

.flow .card {
  padding: 1vw;
  box-shadow: 0 14px 50px rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 1vw;
}

.flow img {
  height: 15vw;
  width: 15vw;
}

.flow .splide__slide .title {
  font-size: 1.5vw;
  font-weight: bold;
  color: #000;
}

.flow .splide__slide .description {
  font-size: 1.25vw;
  margin-top: 1.04vw;
  color: #707070;
}

.album {
  margin-top: 5.21vw;
  padding-bottom: 2vw;
}

.album img {
  width: 100%;
  height: 18vw;
  border-radius: 1.5vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.reason {
  padding: 0 10vw;
  padding-top: 5.21vw;
}

.reason > .title {
  font-size: 3.33vw;
  font-weight: bold;
  color: #ff5a2a;
  text-align: center;
}

.reason > .description {
  font-size: 1.35vw;
  margin-top: 1.04vw;
  margin-bottom: 2.92vw;
  color: #ff5a2a;
  text-align: center;
}

.reason img {
  width: 20vw;
  height: 20vw;
}

.reason .accordion-item {
  background-color: #fff;
  border: 0;
  margin-bottom: 2vw;
  border-radius: 1vw;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.06);
}

.reason .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.reason .accordion-button {
  background-color: unset;
  color: #fdb23f;
  font-size: 1.35vw;
  font-weight: bold;
  padding: 1.5vw;
}

.reason .accordion-button:hover {
  color: #ff5a2a;
}

.reason .accordion-button:active {
  box-shadow: unset;
}

.reason .accordion-button:not(.collapsed) {
  background-color: unset;
  color: #ff5a2a;
  box-shadow: unset;
}

.reason .accordion-body {
  padding: 1.5vw;
  padding-top: 0.5vw;
}

.reason .accordion-item p {
  font-size: 1.15vw;
  font-weight: unset;
  color: #707070;
}

.review {
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.review .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.review .elementor-shape svg {
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.review .elementor-shape-top {
  top: -1px;
}

.review .elementor-shape-bottom {
  transform: rotate(180deg);
  bottom: -1px;
}

.review .elementor-shape-top svg,
.elementor-shape-bottom svg {
  height: 6vw;
}

.review .elementor-shape .elementor-shape-fill {
  fill: #f8eee2;
  transform-origin: center;
  transform: rotateY(0deg);
}

.review .parallax {
  padding: 8vw 10vw;
  text-align: center;
}

.review .parallax .title {
  font-size: 3.33vw;
  font-weight: bold;
  color: #f8eee2;
  margin-bottom: 2.6vw;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.review .parallax .splide {
  padding-left: 3vw;
  padding-right: 3vw;
}

.review .parallax .card {
  border-radius: 1vw;
  border: 0;
  background: #f3f4f6;
  padding: 2vw;
  padding-bottom: 7vw;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.review .parallax .card svg {
  color: #cdd1d4;
  transform: scale(2.5);
  margin-bottom: 2vw;
}

.review .parallax .card .text {
  font-size: 1.5vw;
  color: #000;
  text-align: left;
}

.review .parallax .card .people {
  background: #f8eee2;
  width: max-content;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 1vw;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.5vw 1vw;
}

.review .parallax .card .people .photo {
  width: 4vw;
  height: 4vw;
  border-radius: 3vw;
}

.review .parallax .card .people .name {
  font-size: 1.2vw;
  font-weight: bold;
  color: #000;
}

.review .parallax .card .people .account {
  font-size: 1vw;
  color: #707070;
}

.faq {
  padding: 0 10vw;
  padding-top: 5.21vw;
}

.faq > .title {
  font-size: 3.33vw;
  font-weight: bold;
  color: #ff5a2a;
  text-align: center;
}

.faq > .description {
  font-size: 1.35vw;
  margin-top: 1.04vw;
  margin-bottom: 2.92vw;
  color: #ff5a2a;
  text-align: center;
}

.faq img {
  width: 20vw;
  height: 20vw;
}

.faq .accordion-item {
  background-color: #fff;
  border: 0;
  margin-bottom: 2vw;
  border-radius: 1vw;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.06);
}

.faq .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.faq .accordion-button {
  background-color: unset;
  color: #fdb23f;
  font-size: 1.35vw;
  font-weight: bold;
  padding: 1.5vw;
}

.faq .accordion-button:hover {
  color: #ff5a2a;
}

.faq .accordion-button:active {
  box-shadow: unset;
}

.faq .accordion-button:not(.collapsed) {
  background-color: unset;
  color: #ff5a2a;
  box-shadow: unset;
}

.faq .accordion-body {
  padding: 1.5vw;
  padding-top: 0.5vw;
}

.faq .accordion-item p {
  font-size: 1.15vw;
  font-weight: unset;
  color: #707070;
}

/* mobile */
@media (max-width: 991.98px) {
  .btn-scrolltop {
    bottom: 5vw;
    left: 5vw;
    padding: 2vw;
  }

  .btn-whatsapp {
    bottom: 5vw;
    right: 5vw;
    padding: 2vw;
  }

  .header {
    border-bottom-left-radius: 10vw;
    border-bottom-right-radius: 10vw;
    padding: 5vw 5vw;
  }

  .header > .title {
    font-size: 8vw;
    width: unset;
  }

  .header > .subtitle {
    font-size: 4vw;
    font-weight: 600;
    margin-top: 3vw;
    width: unset;
  }

  .header .button {
    margin-top: 5vw;
    margin-bottom: unset;
  }

  .header .btn-contact,
  .header .btn-why {
    padding: 2vw 3vw;
    font-size: 3vw;
    margin-right: unset;
    border-radius: 10vw;
    display: block;
    margin-bottom: 4vw;
  }

  .header .illustration {
    right: 9vw;
  }

  .header .count {
    position: absolute;
    bottom: -25vh;
    width: 100%;
    left: 2.5vw;
  }

  .header .count > .col-3 {
    width: 50%;
    margin-bottom: 5vw;
  }

  .header .count .card {
    border-radius: 2.5vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    width: 100%;
    border: 0;
  }

  .header .count .card .number {
    font-size: 8vw;
  }

  .header .count .card .text,
  .services .count .card .text {
    font-size: 3vw;
    margin-top: 0.52vw;
  }

  .sitters {
    padding: 0 5vw;
    margin-top: 60vw;
  }

  .sitters > .title {
    font-size: 8vw;
    margin-bottom: 10vw;
  }

  .sitters .col-6 {
    margin-bottom: 15vw;
  }

  .sitters .card {
    border-radius: 3vw;
    padding: 3vw;
    height: 100%;
  }

  .sitters .card .picture {
    width: 15vw;
    height: 15vw;
    border-radius: 15vw;
    top: -8vw;
  }

  .sitters .card svg {
    height: 5vw;
  }

  .sitters .card .name {
    font-size: 4vw;
    margin-bottom: 1vw;
  }

  .sitters .card .row p {
    font-size: 3vw;
  }

  .sitters .btn-seeall {
    padding: 2vw 3vw;
    font-size: 3vw;
    border-radius: 10vw;
    margin-top: -5vw;
  }

  .services {
    background-position: center;
    background-clip: border-box;
    margin-top: 5vw;
  }

  .services .parallax {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .services .parallax > .title {
    font-size: 8vw;
  }

  .services .parallax > .desc {
    font-size: 4vw;
    margin-bottom: 5vw;
  }

  .services .parallax .row .col-12.col-lg-6 {
    margin-bottom: 5vw;
  }

  .services .parallax .row .card {
    width: 100%;
    padding: 5vw 2.6vw;
    border-radius: 3vw;
  }

  .services .parallax .row .card .col-4 {
    width: 100%;
    margin-bottom: 2vw;
  }

  .services .parallax .row .card .col-8 {
    width: 100%;
    text-align: center;
  }

  .services .parallax .row .card .photo {
    width: 15vw;
    height: 15vw;
  }

  .services .parallax .row .card .title {
    font-size: 4vw;
  }

  .services .parallax .row .card .description {
    font-size: 3vw;
    margin-top: 2vw;
  }

  .services .parallax .row .card .btn-contact {
    padding: 2vw 3vw;
    font-size: 3vw;
    border-radius: 10vw;
    margin-top: 2vw;
  }

  .flow {
    padding: 0 5vw;
    margin-top: 15vw;
  }

  .flow > .title {
    font-size: 8vw;
  }

  .flow > .description {
    font-size: 4vw;
    margin-bottom: 5vw;
  }

  .flow .state {
    display: none;
  }

  .flow .splide__arrow.splide__arrow--prev,
  .flow .splide__arrow.splide__arrow--next {
    left: 0vw;
  }

  .splide__arrows--ttb .splide__arrow--prev svg,
  .splide__arrows--ttb .splide__arrow--next svg {
    font-size: 20vw;
  }

  .flow .splide__slide {
    margin-left: 5vw;
  }

  .flow .card {
    padding: 5vw 2.6vw;
    border-radius: 3vw;
    text-align: center;
  }

  .flow .col-5 {
    width: 100%;
    align-content: center;
    margin-bottom: 4vw;
  }

  .flow .col-7 {
    width: 100%;
  }

  .flow .splide__slide .title {
    font-size: 4vw;
  }

  .flow .splide__slide .description {
    font-size: 3vw;
    margin-top: 1.04vw;
  }

  .album {
    margin-top: 15vw;
    padding-bottom: 2vw;
  }

  .album img {
    width: 100%;
    height: 45vw;
    border-radius: 3vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .reason {
    padding: 0 5vw;
    padding-top: 15vw;
  }

  .reason > .title {
    font-size: 8vw;
  }

  .reason > .description {
    font-size: 4vw;
    margin-top: 1.04vw;
    margin-bottom: 5vw;
  }

  .reason img {
    width: 50vw;
    height: 50vw;
  }

  .reason .col-4,
  .reason .col-7 {
    width: 100%;
  }

  .reason .col-4 {
    margin-bottom: 5vw;
  }

  .reason .accordion-button {
    font-size: 4vw;
    padding: 4vw;
  }

  .reason .accordion-body {
    padding: 4vw;
    padding-top: 0.5vw;
  }

  .reason .accordion-item {
    border-radius: 3vw;
  }

  .reason .accordion-item p {
    font-size: 3vw;
  }

  .review {
    background-position: center;
    background-clip: border-box;
    margin-top: 15vw;
  }

  .review .parallax {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .review .parallax .title {
    font-size: 15vw;
  }

  .review .parallax .desc {
    font-size: 5vw;
    margin-top: 5vw;
  }

  .review .parallax .title {
    font-size: 8vw;
    margin-bottom: 5vw;
  }

  .review .parallax .card {
    border-radius: 3vw;
    padding: 8vw 15vw;
    padding-bottom: 20vw;
    width: 100%;
  }

  .review .parallax .card svg {
    margin-bottom: 5vw;
  }

  .review .parallax .card .text {
    font-size: 4vw;
  }

  .review .parallax .card .people {
    position: absolute;
    border-radius: 3vw;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 2vw 3vw;
  }

  .review .parallax .card .people .photo {
    width: 10vw;
    height: 10vw;
    border-radius: 10vw;
  }

  .review .parallax .card .people .name {
    font-size: 4vw;
  }

  .review .parallax .card .people .account {
    font-size: 3.5vw;
  }

  .faq {
    padding: 0 5vw;
    padding-top: 15vw;
  }

  .faq > .title {
    font-size: 8vw;
  }

  .faq > .description {
    font-size: 4vw;
    margin-bottom: 5vw;
  }

  .faq .col-6 {
    width: 100%;
    margin-bottom: 5vw;
  }

  .faq img {
    width: 50vw;
    height: 50vw;
  }

  .faq .accordion-button {
    font-size: 4vw;
    padding: 4vw;
  }

  .faq .accordion-body {
    padding: 4vw;
    padding-top: 0.5vw;
  }

  .faq .accordion-item {
    border-radius: 3vw;
  }

  .faq .accordion-item p {
    font-size: 3vw;
  }
}
</style>
