<template>
  <div>
    <Loader v-if="isLoading" color="#000" />
    <div class="top">
      <div class="row">
        <div class="col-6">
          <p class="title">Galleries</p>
        </div>
        <div class="col-6 text-end">
          <b-button @click="handleOpenModalCreate" class="new ms-auto">
            New Image
          </b-button>
        </div>
      </div>
    </div>
    <div class="content" v-if="galleries[0]">
      <div class="row">
        <div
          v-for="(item, index) in galleries"
          :key="item.id"
          class="col-6 mb-3"
        >
          <div class="row mb-5">
            <div class="col-8">
              <b-form-group
                :label="`${index + 1}${
                  index === 0
                    ? 'st'
                    : index === 1
                    ? 'nd'
                    : index === 2
                    ? 'rd'
                    : 'th'
                } Image`"
              >
                <b-form-input
                  v-model="galleries[index].img"
                  type="text"
                  :disabled="disabledForm[index].img"
                ></b-form-input>
              </b-form-group>

              <div class="d-flex mt-3">
                <b-button
                  @click="updateGalleries(item.id, 0)"
                  class="update btn"
                >
                  Update
                </b-button>
                <b-button
                  @click="deleteGalleries(item.id)"
                  class="delete btn ms-2"
                >
                  Delete
                </b-button>
              </div>
            </div>
            <div class="col-auto">
              <a :href="galleries[index].img" target="_blank">
                <img
                  :src="galleries[index].img"
                  alt="illustration"
                  width="80"
                  height="80"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import Loader from '@/landing/components/Loader.vue'
import { openModal } from '@kolirt/vue-modal'
import DefaultModal from '@/admin/components/DefaultModal.vue'

export default {
  name: 'GalleriesPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      galleries: [],
      disabledForm: [],
    }
  },
  created() {
    this.getGalleries()
  },
  methods: {
    handleOpenModalCreate() {
      openModal(DefaultModal, {
        title: 'Create Image',
        form: [
          {
            label: 'Image',
            type: 'text',
          },
        ],
      })
        .then((data) => {
          this.createGalleries(data)
        })
        .catch(() => {
          return
        })
    },
    async getGalleries() {
      this.isLoading = true
      await api
        .get('galleries')
        .then((response) => {
          this.galleries = response.data

          const disabledItems = response.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems
        })
        .catch((error) => {
          console.error(error)
          toast('Failed to fetch data', {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async updateGalleries(id, type) {
      const typeData = ['img']

      const index = this.disabledForm.findIndex((item) => item.id === id)
      let updatedItem = this.disabledForm[index]
      if (updatedItem[typeData[type]]) {
        updatedItem = {
          ...updatedItem,
          [typeData[type]]: false,
        }
        const newDisabledForm = [
          ...this.disabledForm.slice(0, index),
          updatedItem,
          ...this.disabledForm.slice(index + 1),
        ]
        this.disabledForm = newDisabledForm

        return
      }

      const value = this.galleries.filter((heading) => heading.id === id)[0].img

      if (value === null || value === '') {
        return toast('Value cannot be empty', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .patch('galleries-update', {
          id,
          img: value,
        })
        .then((response) => {
          this.galleries = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false

          updatedItem = {
            ...updatedItem,
            [typeData[type]]: true,
          }
          const newDisabledForm = [
            ...this.disabledForm.slice(0, index),
            updatedItem,
            ...this.disabledForm.slice(index + 1),
          ]
          this.disabledForm = newDisabledForm

          return
        })
    },
    async deleteGalleries(id) {
      //confirm delete
      if (!confirm('Are you sure you want to delete this item?')) {
        return
      }

      this.isLoading = true
      await api
        .delete('galleries-delete', {
          data: {
            id,
          },
        })
        .then((response) => {
          this.galleries = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async createGalleries(data) {
      const value = data[0].value

      if (value === null || value === '') {
        return toast('Value cannot be empty', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .post('galleries-create', {
          img: value,
        })
        .then((response) => {
          const disabledItems = response.data.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems

          this.galleries = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  border-bottom: 1px solid #e6e6e6;
  padding: 5vh 2.5vh 0 2.5vh;
  background: #fff;
  height: 15vh;
}

.top .title {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
}

.top .btn,
.modal .btn.save {
  height: max-content;
  background: #28a745;
  border: 1px solid #28a745;
  color: #fff;
}

.top .btn:hover,
.top .btn:active,
.top .btn:active,
.modal .btn.save:hover,
.modal .btn.save:active,
.modal .btn.save:active {
  background: #28a745;
  border-color: #28a745;
  box-shadow: 0 8px 25px -8px #28a745;
}

.content {
  padding: 2.5vh;
  background: #f5f5f5;
  border-bottom-left-radius: 3vh;
  border-bottom-right-radius: 3vh;
  height: 80vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content .title {
  font-size: 20px;
  font-weight: bold;
  color: #111111;
}

.content fieldset {
  width: 80%;
  margin-bottom: 0 !important;
}

.content .update.btn {
  height: max-content;
  background: #ff5a2a;
  border: 1px solid #ff5a2a;
}

.content .update.btn:hover,
.content .update.btn:active,
.content .update.btn:active {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

.content .delete.btn {
  height: max-content;
  background: #dc3545;
  border: 1px solid #dc3545;
}

.content .delete.btn:hover,
.content .delete.btn:active,
.content .delete.btn:active {
  background: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 8px 25px -8px #dc3545;
}

@media (min-width: 1440px) {
  .top {
    padding: 5vh 15vh 0 15vh;
  }

  .content {
    padding: 5vh 15vh;
  }
}
</style>
