<template>
  <div>
    <Navbar />
    <h1>Contact</h1>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/landing/components/Navbar.vue'
import Footer from '@/landing/components/Footer.vue'

export default {
  name: 'Contact',
  components: {
    Navbar,
    Footer,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
