<template>
  <div>
    <Loader v-if="isLoading" color="#000" />
    <div class="top">
      <div class="row">
        <div class="col-6">
          <p class="title">Reviews</p>
        </div>
        <div class="col-6 text-end">
          <b-button @click="handleOpenModalCreate" class="new ms-auto">
            New Review
          </b-button>
        </div>
      </div>
    </div>
    <div class="content" v-if="reviews[0]">
      <div v-for="(item, index) in reviews" :key="item.id" class="row">
        <div class="col-12">
          <p class="title">
            {{ index + 1
            }}{{
              index === 0
                ? 'st'
                : index === 1
                ? 'nd'
                : index === 2
                ? 'rd'
                : 'th'
            }}
            Review
          </p>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Name">
            <b-form-input
              v-model="reviews[index].name"
              type="text"
              :disabled="disabledForm[index].name"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Account">
            <b-form-input
              v-model="reviews[index].account"
              type="text"
              :disabled="disabledForm[index].account"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Profile Picture">
            <b-form-input
              v-model="reviews[index].img"
              type="text"
              :disabled="disabledForm[index].img"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Review">
            <b-form-input
              v-model="reviews[index].value"
              type="text"
              :disabled="disabledForm[index].value"
              maxlength="125"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <a :href="reviews[index].img" target="_blank">
            <img
              :src="reviews[index].img"
              alt="illustration"
              width="80"
              height="80"
            />
          </a>
        </div>

        <div class="col-6 mb-3 d-flex align-items-end text-end">
          <b-button @click="deleteReviews(item.id)" class="delete btn ms-auto">
            Delete
          </b-button>
          <b-button @click="updateReviews(item.id)" class="update ms-2">
            Update
          </b-button>
        </div>

        <div>
          <hr v-if="index !== reviews.length - 1" class="my-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import Loader from '@/landing/components/Loader.vue'
import { openModal } from '@kolirt/vue-modal'
import DefaultModal from '@/admin/components/DefaultModal.vue'

export default {
  name: 'ReviewsPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      reviews: [],
      disabledForm: [],
    }
  },
  created() {
    this.getReviews()
  },
  methods: {
    handleOpenModalCreate() {
      openModal(DefaultModal, {
        title: 'Create Review',
        form: [
          {
            label: 'Name',
            type: 'text',
          },
          {
            label: 'Account',
            type: 'text',
          },
          {
            label: 'Review',
            type: 'text',
          },
          {
            label: 'Image',
            type: 'text',
          },
        ],
      })
        .then((data) => {
          this.createReviews(data)
        })
        .catch(() => {
          return
        })
    },
    async getReviews() {
      this.isLoading = true
      await api
        .get('reviews')
        .then((response) => {
          this.reviews = response.data
          const disabledItems = response.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems
        })
        .catch((error) => {
          console.error(error)
          toast('Failed to fetch data', {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async updateReviews(id) {
      const index = this.disabledForm.findIndex((item) => item.id === id)
      let updatedItem = this.disabledForm[index]
      if (updatedItem.name) {
        updatedItem = {
          id,
          name: false,
          account: false,
          value: false,
          img: false,
        }

        return (this.disabledForm[index] = updatedItem)
      }

      if (
        this.reviews[index].name === '' ||
        this.reviews[index].account === '' ||
        this.reviews[index].value === '' ||
        this.reviews[index].img === ''
      ) {
        return toast('Please fill all fields', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .patch('reviews-update', {
          id,
          name: this.reviews[index].name,
          account: this.reviews[index].account,
          value: this.reviews[index].value,
          img: this.reviews[index].img,
        })
        .then((response) => {
          this.reviews = response.data.data
          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
          updatedItem = {
            id,
            name: true,
            account: true,
            value: true,
            img: true,
          }
          this.disabledForm[index] = updatedItem
          return
        })
    },
    async deleteReviews(id) {
      //confirm delete
      if (!confirm('Are you sure you want to delete this item?')) {
        return
      }

      this.isLoading = true
      await api
        .delete('reviews-delete', {
          data: {
            id,
          },
        })
        .then((response) => {
          this.reviews = response.data.data
          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async createReviews(data) {
      const formData = {
        name: data[0].value,
        account: data[1].value,
        value: data[2].value,
        img: data[3].value,
      }

      if (
        formData.name === '' ||
        formData.account === '' ||
        formData.value === '' ||
        formData.img === ''
      ) {
        return toast('Please fill all fields', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .post('reviews-create', {
          name: formData.name,
          account: formData.account,
          value: formData.value,
          img: formData.img,
        })
        .then((response) => {
          const disabledItems = response.data.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems

          this.reviews = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  border-bottom: 1px solid #e6e6e6;
  padding: 5vh 2.5vh 0 2.5vh;
  background: #fff;
  height: 15vh;
}

.top .title {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
}

.top .btn,
.modal .btn.save {
  height: max-content;
  background: #28a745;
  border: 1px solid #28a745;
  color: #fff;
}

.top .btn:hover,
.top .btn:active,
.top .btn:active,
.modal .btn.save:hover,
.modal .btn.save:active,
.modal .btn.save:active {
  background: #28a745;
  border-color: #28a745;
  box-shadow: 0 8px 25px -8px #28a745;
}

.content {
  padding: 2.5vh;
  background: #f5f5f5;
  border-bottom-left-radius: 3vh;
  border-bottom-right-radius: 3vh;
  height: 80vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content .title {
  font-size: 20px;
  font-weight: bold;
  color: #111111;
}

.content fieldset {
  margin-bottom: 0 !important;
}

.content .update.btn {
  height: max-content;
  background: #ff5a2a;
  border: 1px solid #ff5a2a;
}

.content .update.btn:hover,
.content .update.btn:active,
.content .update.btn:active {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

.content .delete.btn {
  height: max-content;
  background: #dc3545;
  border: 1px solid #dc3545;
}

.content .delete.btn:hover,
.content .delete.btn:active,
.content .delete.btn:active {
  background: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 8px 25px -8px #dc3545;
}

@media (min-width: 1440px) {
  .top {
    padding: 5vh 15vh 0 15vh;
  }

  .content {
    padding: 5vh 15vh;
  }
}
</style>
