import axios from 'axios'

const typeBaseURL = process.env.VUE_APP_BACKEND_BASEURL
const isProd = typeBaseURL === 'production'
const isLocal = typeBaseURL === 'local'

const baseUrl = () => {
  if (isProd) {
    return process.env.VUE_APP_BACKEND_BASEURL_PRODUCTION
  }

  if (isLocal) {
    return process.env.VUE_APP_BACKEND_BASEURL_LOCAL
  }
}

const api = axios.create({
  baseURL: baseUrl(),
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    const excludedUrls = ['login', 'headings', 'counts', 'sitters', 'services', 'flows', 'galleries', 'reasons', 'reviews', 'faqs']
    const method = () => {
      if (config.url.includes('login')) {
        return true
      }

      return config.method === 'get'
    }

    const isExcluded = excludedUrls.some((url) => {
      return config.url.includes(url) && method()
    })

    if (!isExcluded && token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
