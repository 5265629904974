<template>
  <div class="admin">
    <Sidebar />
    <div class="template">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/admin/components/Sidebar.vue'

export default {
  name: 'TemplatePage',
  components: {
    Sidebar,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin {
  height: 100vh;
  padding: 2.5vh;
  background: #111111;
  display: flex;
}

.template {
  width: 90%;
  border-radius: 3vh;
  background: #fff;
  height: 95vh;
  transition: margin-left 0.5s;
}

@media (min-width: 1440px) {
  .admin {
    padding-left: 5vw;
  }
}
</style>
