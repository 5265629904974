<template>
  <Loader v-if="isLoading" />
  <div class="home">
    <Navbar
      v-if="links.length"
      v-bind:links="links"
      @trigger-lang="handleLangOnChange()"
    />
    <b-button
      v-if="scrollY > 600"
      ref="scrollToTop"
      class="btn-scrolltop"
      @click="handleScrollTop()"
    >
      <SvgIcon type="mdi" :path="iconPath[1]" />
    </b-button>
    <b-button class="btn-whatsapp" @click="handleWhatsapp()">
      <SvgIcon type="mdi" :path="iconPath[2]" />
    </b-button>
    <div class="header">
      <div
        class="elementor-shape elementor-shape-top"
        data-negative="false"
        bis_skin_checked="1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M0 0L2600 0 2600 69.1 0 0z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.5;"
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.25;"
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </div>
      <div
        class="elementor-shape elementor-shape-bottom"
        data-negative="false"
        bis_skin_checked="1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M0 0L2600 0 2600 69.1 0 0z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.5;"
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            class="elementor-shape-fill"
            style="opacity: 0.25;"
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </div>
      <div class="parallax">
        <p class="title mb-0">
          {{ this.lang === 'id' ? 'Pengasuh Kami' : 'Our Sitters' }}
        </p>
        <div class="desc">
          {{
            this.lang === 'id'
              ? 'Ketahui lebih banyak tentang pengasuh kucing kami termasuk pengalaman, keterampilan, dan kepribadian mereka yang menawan!'
              : 'Get to know more about our cat sitters including their experiences, skills, and lovely personalities!'
          }}
        </div>
      </div>
    </div>

    <div class="filter">
      <div class="row">
        <div class="col-12 service">
          <div class="justify-content-center align-item-center d-flex">
            <p class="mb-0">
              {{
                this.filter.serviceOption[0]?.charAt(0).toUpperCase() +
                this.filter.serviceOption[0]?.slice(1) +
                ' Visit'
              }}
            </p>
            <b-form-checkbox
              v-model="filter.service"
              switch
              @change="handleFilter()"
              class="mx-3"
            ></b-form-checkbox>
            <p class="mb-0">
              {{
                this.filter.serviceOption[1]?.charAt(0).toUpperCase() +
                this.filter.serviceOption[1]?.slice(1) +
                ' Visit'
              }}
            </p>
          </div>
        </div>
        <div class="col-12 city text-center">
          <b-button
            v-for="(item, index) in filter.cityOption"
            :key="index"
            @click="handleFilter(item, 'city')"
            :class="{ active: item === filter.city, 'me-2': true }"
          >
            {{ item }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="sitters">
      <div class="row">
        <div
          v-for="(item, index) in dataShowed"
          :key="index"
          class="col-6 col-lg-3 text-center"
        >
          <div class="card">
            <img :src="item.img" alt="" class="img-fluid" draggable="false" />
            <div class="row">
              <div class="col-12">
                <a :href="item.ig" target="_blank">
                  <SvgIcon type="mdi" :path="iconPath[3]" />
                </a>
                <p class="name mb-0">
                  {{ item.name }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p
                  class="d-flex align-items-center justify-content-center mb-0"
                >
                  {{ `${item.city} || ${item.rating}` }}
                  <SvgIcon type="mdi" :path="iconPath[0]" size="20" />
                </p>
                <p class="mb-0">
                  {{
                    `${
                      item.service === 'all'
                        ? 'Home/Care'
                        : item.service.charAt(0).toUpperCase() +
                          item.service.slice(1)
                    } Visit`
                  }}
                </p>
                <p class="mb-0">{{ item.level }}</p>
              </div>
            </div>

            <div class="accordion" id="accordionSitters">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapseIntro${index}`"
                    :aria-controls="`#collapseIntro${index}`"
                  >
                    {{ this.lang === 'id' ? 'Pengenalan' : 'Introduction' }}
                  </button>
                </h2>
                <div
                  :id="`collapseIntro${index}`"
                  :class="`accordion-collapse collapse`"
                  data-bs-parent="#accordionSitters"
                >
                  <div class="accordion-body">
                    <p>
                      {{ item.intro }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapseSkillsExperience${index}`"
                    :aria-controls="`#collapseSkillsExperience${index}`"
                  >
                    {{
                      this.lang === 'id'
                        ? 'Keterampilan & Pengalaman'
                        : 'Skills & Experience'
                    }}
                  </button>
                </h2>
                <div
                  :id="`collapseSkillsExperience${index}`"
                  :class="`accordion-collapse collapse`"
                  data-bs-parent="#accordionSitters"
                >
                  <div class="accordion-body">
                    <p>
                      {{ item.intro }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="links.length" v-bind:links="links" />
  </div>
</template>

<script>
import api from '@/api'
import Navbar from '@/landing/components/Navbar.vue'
import Footer from '@/landing/components/Footer.vue'
import Loader from '@/landing/components/Loader.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiStar, mdiArrowUpBold, mdiWhatsapp, mdiInstagram } from '@mdi/js'

export default {
  name: 'ProfilePage',
  components: {
    Navbar,
    Footer,
    Loader,
    SvgIcon,
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    this.getLinks()
    window.addEventListener('scroll', this.handleScroll)
  },
  data() {
    return {
      isLoading: true,
      iconPath: [mdiStar, mdiArrowUpBold, mdiWhatsapp, mdiInstagram],
      scrollTimer: 0,
      scrollY: 0,
      links: [],
      data: [],
      dataShowed: [],
      filter: {
        city: 'Jakarta',
        service: false, // false === home
        cityOption: [],
        serviceOption: ['home', 'care', 'all'],
      },
      lang: localStorage.getItem('lang') || 'en',
    }
  },
  methods: {
    handleFilter(value, type) {
      if (type === 'city') {
        this.filter.city = value
      }

      const service = this.filter.service
        ? this.filter.serviceOption[1]
        : this.filter.serviceOption[0]

      this.dataShowed = this.data.filter(
        (item) =>
          item.city === this.filter.city &&
          (item.service === service || item.service === 'all'),
      )
    },
    handleScroll() {
      if (this.scrollTimer) return
      this.scrollTimer = setTimeout(() => {
        this.scrollY = window.scrollY
        clearTimeout(this.scrollTimer)
        this.scrollTimer = 0
      }, 100)
    },
    handleScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleLangOnChange() {
      this.lang = this.lang === 'en' ? 'id' : 'en'
    },
    handleWhatsapp() {
      window.open(
        'https://api.whatsapp.com/send/?phone=%206287730903400&text=Halo%2C+Saya+tertarik+untuk+memesan+jasa+cat+sitter&app_absent=0',
        '_blank',
      )
    },
    async getLinks() {
      this.isLoading = true
      await api
        .get('links')
        .then((response) => {
          this.links = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.getSitters()
        })
    },
    async getSitters() {
      await api
        .get('sitters')
        .then((response) => {
          this.filter.cityOption = [
            ...new Set(response.data.map((item) => item.city)),
          ]

          this.data = response.data

          const service = this.filter.service
            ? this.filter.serviceOption[1]
            : this.filter.serviceOption[0]

          this.dataShowed = this.data.filter(
            (item) =>
              item.city === this.filter.city &&
              (item.service === service || item.service === 'all'),
          )
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-scrolltop {
  position: fixed;
  bottom: 2vw;
  left: 2vw;
  z-index: 1029;
  padding: 1vw;
  border: 0;
  background: #fdb23f;
  border-radius: 50%;
}

.btn-scrolltop:hover {
  background: #ff5a2a;
}

.btn-whatsapp {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  z-index: 1029;
  padding: 1vw;
  border: 0;
  border-radius: 50%;
}

.btn-whatsapp:hover {
  background: #25d366;
}

.header {
  background-image: url(https://nekoya.co/wp-content/uploads/2023/09/NEKO-KAP-JUNE23-59-1.jpg);
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.header .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.header .elementor-shape svg {
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.header .elementor-shape-top {
  top: -1px;
}

.header .elementor-shape-bottom {
  transform: rotate(180deg);
  bottom: -1px;
}

.header .elementor-shape-top svg,
.elementor-shape-bottom svg {
  height: 6vw;
}

.header .elementor-shape .elementor-shape-fill {
  fill: #f8eee2;
  transform-origin: center;
  transform: rotateY(0deg);
}

.header .parallax {
  padding: 8vw 10vw;
  text-align: center;
}

.header .parallax .title {
  color: #f8eee2;
  font-size: 8vw;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.header .parallax .desc {
  color: #f8eee2;
  font-size: 1.25vw;
  font-weight: 600;
  margin-top: 1.56vw;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.filter {
  padding: 4vw 10vw;
}

.filter .service {
  margin-bottom: 2vw;
}

.filter .service p {
  color: #ff5a2a;
  font-size: 1.25vw;
  font-weight: bold;
}

.filter .city button {
  background: #fdb23f;
  border: 0;
  border-radius: 2.5vw;
  color: #fff;
  font-size: 1.25vw;
  font-weight: 600;
  padding: 0.63vw 1.25vw;
}

.filter .city button.active {
  cursor: unset;
}

.filter .city button.active,
.filter .city button:hover,
.filter .city button:active {
  background: #ff5a2a;
}

.sitters {
  padding: 0 10vw;
}

.sitters .col-6 {
  margin-bottom: 2vw;
}

.sitters .card {
  background: #fff;
  border: 0;
  border-radius: 1.25vw;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 1.5vw;
}

.sitters .card img {
  height: 10vw;
}

.sitters .card a svg {
  margin-top: 2vw;
}

.sitters .card .name {
  color: #000;
  font-size: 1.3vw;
  font-weight: 600;
}

.sitters .card svg {
  color: #fcb017;
}

.sitters .card .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #f8eee2;
  margin-top: 1vw;
}

.sitters .card .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.sitters .accordion-button {
  background-color: unset;
  padding: 0.2vw 0.5vw;
}

.sitters .card .accordion-button:hover {
  color: #ff5a2a;
}

.sitters .card .accordion-button:active {
  box-shadow: unset;
}

.sitters .card .accordion-button:not(.collapsed) {
  background-color: unset;
  color: #ff5a2a;
  box-shadow: unset;
}

.sitters .card .accordion-body {
  padding: 0.5vw;
  text-align: justify;
}

.sitters .card .accordion-item p {
  font-weight: unset;
  color: #000;
}

@media (max-width: 991.98px) {
  .btn-scrolltop {
    bottom: 5vw;
    left: 5vw;
    padding: 2vw;
  }

  .btn-whatsapp {
    bottom: 5vw;
    right: 5vw;
    padding: 2vw;
  }

  .header {
    background-position: center;
    background-clip: border-box;
  }

  .header .parallax {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .header .parallax .title {
    font-size: 15vw;
  }

  .header .parallax .desc {
    font-size: 5vw;
    margin-top: 5vw;
  }

  .filter {
    padding: 5vw;
  }

  .filter .service {
    margin-bottom: 5vw;
  }

  .filter .service p {
    font-size: 5vw;
  }

  .filter .city button {
    padding: 2vw 3vw;
    font-size: 3vw;
    border-radius: 10vw;
    margin-bottom: 3vw;
  }

  .sitters {
    padding: 0 5vw;
  }

  .sitters .col-6 {
    margin-bottom: 5vw;
  }

  .sitters .card {
    padding: 3vw;
    border-radius: 3vw;
  }

  .sitters .card img {
    height: 25vw;
  }

  .sitters .card svg {
    height: 5vw;
  }

  .sitters .card .name {
    font-size: 4vw;
    margin-bottom: 1vw;
  }

  .sitters .card p,
  .sitters .card .accordion-button,
  .sitters .card .accordion-body p {
    font-size: 3vw;
  }
}
</style>
