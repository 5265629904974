<template>
  <div>
    <Loader v-if="isLoading" color="#000" />
    <div class="top">
      <p class="title">Flows</p>
    </div>
    <div class="content" v-if="flows[0]">
      <div v-for="(item, index) in flows" :key="item.id" class="row">
        <div class="col-12">
          <p class="title">
            {{ index + 1
            }}{{
              index === 0
                ? 'st'
                : index === 1
                ? 'nd'
                : index === 2
                ? 'rd'
                : 'th'
            }}
            Flow
          </p>
        </div>

        <div class="col-6 mb-3 d-flex align-items-end">
          <b-form-group label="Title (ID)">
            <b-form-input
              v-model="flows[index].title_id"
              type="text"
              :disabled="disabledForm[index].title_id"
            ></b-form-input>
          </b-form-group>
          <b-button
            class="update ms-auto"
            @click="updateFlows(flows[index].id, 0)"
          >
            Update
          </b-button>
        </div>

        <div class="col-6 mb-3 d-flex align-items-end">
          <b-form-group label="Title (EN)">
            <b-form-input
              v-model="flows[index].title_en"
              type="text"
              :disabled="disabledForm[index].title_en"
            ></b-form-input>
          </b-form-group>
          <b-button
            class="update ms-auto"
            @click="updateFlows(flows[index].id, 1)"
          >
            Update
          </b-button>
        </div>

        <div class="col-6 mb-3 d-flex align-items-end">
          <b-form-group label="Subtitle (ID)">
            <b-form-textarea
              v-model="flows[index].subtitle_id"
              rows="3"
              max-rows="6"
              :disabled="disabledForm[index].subtitle_id"
            ></b-form-textarea>
          </b-form-group>
          <b-button
            class="update ms-auto"
            @click="updateFlows(flows[index].id, 2)"
          >
            Update
          </b-button>
        </div>

        <div class="col-6 mb-3 d-flex align-items-end">
          <b-form-group label="Subtitle (EN)">
            <b-form-textarea
              v-model="flows[index].subtitle_en"
              rows="3"
              max-rows="6"
              :disabled="disabledForm[index].subtitle_en"
            ></b-form-textarea>
          </b-form-group>
          <b-button
            class="update ms-auto"
            @click="updateFlows(flows[index].id, 3)"
          >
            Update
          </b-button>
        </div>

        <div class="col-6 mb-3 d-flex align-items-end">
          <b-form-group label="Illustration">
            <b-form-input
              v-model="flows[index].img"
              type="text"
              :disabled="disabledForm[index].img"
            ></b-form-input>
          </b-form-group>
          <b-button
            class="update ms-auto"
            @click="updateFlows(flows[index].id, 4)"
          >
            Update
          </b-button>
        </div>

        <div class="col-6">
          <img
            :src="flows[index].img"
            alt="illustration"
            width="80"
            height="80"
          />
        </div>

        <div>
          <hr v-if="index !== flows.length - 1" class="my-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import Loader from '@/landing/components/Loader.vue'

export default {
  name: 'FlowsPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      flows: [],
      disabledForm: [],
    }
  },
  created() {
    this.getFlows()
  },
  methods: {
    async getFlows() {
      this.isLoading = true
      await api
        .get('flows')
        .then((response) => {
          this.flows = response.data
          const disabledItems = response.data.map((item) => {
            const disabledItem = {}

            for (const key in item) {
              if (key !== 'section_type') {
                if (item[key]) {
                  if (key === 'id') {
                    disabledItem[key] = item[key]
                  } else {
                    disabledItem[key] = true
                  }
                }
              }
            }

            return disabledItem
          })
          this.disabledForm = disabledItems
        })
        .catch((error) => {
          console.error(error)
          toast('Failed to fetch data', {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async updateFlows(id, type) {
      const typeData = [
        'title_id',
        'title_en',
        'subtitle_id',
        'subtitle_en',
        'img',
      ]

      const index = this.disabledForm.findIndex((item) => item.id === id)
      let updatedItem = this.disabledForm[index]
      if (updatedItem[typeData[type]]) {
        updatedItem = {
          ...updatedItem,
          [typeData[type]]: false,
        }
        const newDisabledForm = [
          ...this.disabledForm.slice(0, index),
          updatedItem,
          ...this.disabledForm.slice(index + 1),
        ]
        this.disabledForm = newDisabledForm

        return
      }

      let value = null
      switch (type) {
        case 0:
          value = this.flows.filter((heading) => heading.id === id)[0].title_id
          break
        case 1:
          value = this.flows.filter((heading) => heading.id === id)[0].title_en
          break
        case 2:
          value = this.flows.filter((heading) => heading.id === id)[0]
            .subtitle_id
          break
        case 3:
          value = this.flows.filter((heading) => heading.id === id)[0]
            .subtitle_en
          break
        case 4:
          value = this.flows.filter((heading) => heading.id === id)[0].img
          break
        default:
          break
      }

      if (value === null || value === '') {
        return toast('Value cannot be empty', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .patch(`flows`, {
          id,
          type: typeData[type],
          value,
        })
        .then((response) => {
          this.flows = response.data.data

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false

          updatedItem = {
            ...updatedItem,
            [typeData[type]]: true,
          }
          const newDisabledForm = [
            ...this.disabledForm.slice(0, index),
            updatedItem,
            ...this.disabledForm.slice(index + 1),
          ]
          this.disabledForm = newDisabledForm

          return
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  border-bottom: 1px solid #e6e6e6;
  padding: 5vh 2.5vh 0 2.5vh;
  background: #fff;
  height: 15vh;
}

.top .title {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
}

.content {
  padding: 2.5vh;
  background: #f5f5f5;
  border-bottom-left-radius: 3vh;
  border-bottom-right-radius: 3vh;
  height: 80vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content .title {
  font-size: 20px;
  font-weight: bold;
  color: #111111;
}

.content fieldset {
  width: 80%;
  margin-bottom: 0 !important;
}

.content .btn {
  height: max-content;
  background: #ff5a2a;
  border: 1px solid #ff5a2a;
}

.content .btn:hover,
.content .btn:active,
.content .btn:active {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

@media (min-width: 1440px) {
  .top {
    padding: 5vh 15vh 0 15vh;
  }

  .content {
    padding: 5vh 15vh;
  }
}
</style>
